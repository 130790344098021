.loading-wrapper {
    align-items: center;
    background: transparent;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10000;
    height: 100vh;
}

.loading-wrapper-overlay {
    align-items: center;
    border-radius:12px;
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
}


.loading-wrapper .card {
    background-color: #e9ecef;
}

.loading-text {
    font-size: 1.1rem;
    color: #75716a;
}

.hollow-dots-spinner,
.hollow-dots-spinner * {
    box-sizing: border-box;
}

.hollow-dots-spinner {
    height: 15px;
    width: calc(30px * 3);
    margin-bottom: 0.8rem;
}

.hollow-dots-spinner .dot {
    width: 15px;
    height: 15px;
    margin: 0 calc(15px / 2);
    border: calc(15px / 5) solid#3b80f4;
    background: #3b80f4;
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(300ms * 3);

}

@keyframes hollow-dots-spinner-animation {
    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}