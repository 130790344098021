@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');


$primary: rgb(72, 95, 199);

// Set the sans-serif font
$font-family-sans-serif: 'Nunito', sans-serif;

// Maybe set a difference heading font family
$headings-font-family: 'Nunito', sans-serif;

// Import only what you need from Bulma
@import "~bootstrap/scss/bootstrap.scss";