@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');
@import './plugins/bootstrap.scss';
@import './plugins/loading.scss';

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  // font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2c2e2f;
  text-align: left;
  background-color: #f9f9f9;
}

.brand-link {
  padding: 0.46rem 0.5rem;
}

.jc {
  justify-content: center !important;
}

.ac {
  align-items: center !important;
}

.bg-gradient-blue {
  background: rgb(30, 64, 175);
  background: linear-gradient(90deg, rgba(30, 64, 175, 1) 0%, rgba(59, 130, 246, 1) 100%);
}

.min-h-screen {
  min-height: 100vh;
}

.logo-ticket-page {
  height: 60px;
}

.logo-client {
  width: 150px;
}

.white-logo {
  .top {
    fill: #ffffff;
  }

  .left {
    fill: #ffffff;
  }

  .right {
    fill: #ffffff;
  }

  .dematick-logo-text {
    fill: #ffffff;
  }
}

.bg-half-right {
  background: #eaedff;
}

.login-card-body {
  width: 300px;
}

.ticket {
  // width: 320px;
  // background: #ffffff;
  // height: auto;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, .35) 0px 5px 15px;
}

.ticket img {
  max-width: 100%;
}

.logo-ticket {
  height: 40px;
}


.form-group.floated input {
  height: auto;
  padding: 1.2rem 0.8rem;
  padding-bottom: 0.1rem;
}

.form-group.floated label {
  color: $primary;
  font-size: 0.7em;
  font-weight: normal;
  position: absolute;
  width: 100%;
  margin-top: 0.25rem;
  margin-left: 1px;
  padding: 0 0.8rem;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.form-control:focus {
  border-color: #316bcc;
  box-shadow: 0 0 0 0.06rem #316bcc;
}

.btn-white {
  // background: #fff;
  font-size: 1.3rem;
  font-weight: 700;
  color: #fff;
  padding: 1rem 1rem;
}

.btn-white:hover {
  border-bottom: 2px solid #ffffff;
}

.navbar {
  min-height: 72px;
  border-bottom: 1px solid #3b82f6;
}

.navbar-nav .nav-item a.nav-link {
  // padding: 1.7em 0 .45em;

}

.navbar .nav-item {
  padding: 0 .925rem;
}

.navbar .nav-link {
  color: #ffffff;
  padding: 0.1rem 0rem;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color .3s ease-in-out;
}

.navbar .nav-link.active {
  border-bottom: 1px solid #ffffff;
}


.navbar .nav-link:hover {
  border-bottom: 1px solid #ffffff;
}

.card.card-home {
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .16);
  border-radius: 12px;
}

.circle-store {
  color: #ffffff;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #316bcc;
  -webkit-box-shadow: rgba(199, 199, 199, 0.5) 1px 3px 8px;
  box-shadow: rgba(199, 199, 199, 0.5) 1px 3px 8px;
}

.img-jeux {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px 12px 0 0;
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: #fff;
}

.border-bottom-dashed {
  border-bottom: dashed 1px #9da3a6;
}

.ticket-item:after {
  content: "";
  margin: 0 10px 0 15px;
  width: 100%;
  height: 1px;
  padding-top: 16px;
  border-bottom: dashed 1px #9da3a6;
}

.btn-sm-square {
  padding: 0.2rem 0.3rem;
}

.modal-ticket {
  max-width: 400px;
}

.close-circle {
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 50%;
  border: none;
  padding: .2rem;
  color: #ffffff;
  background: #316bcc;

}

.ticket-container {
  margin-bottom: 8rem;
}

.bottom-auth {
  border-top: 1px solid #bbbaba;
}

.img-max {
  max-width: 300px !important;
}

// Small devices (landscape phones, 576px and up)
@media screen and (min-width: 576px) {
  .img-max {
    max-width: 400px !important;
  }
}

// Medium devices (tablets, 768px and up)
@media screen and (min-width: 768px) {
  .img-max {
    max-width: 600px !important;
  }
}

// Large devices (desktops, 992px and up)
@media screen and (min-width: 992px) {
  .img-max {
    max-width: 600px !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media screen and (min-width: 1200px) {
  .img-max {
    max-width: 600px !important;
  }
}

.ticket-json-container {
  // width: 100%;
  min-width: 370px;
  padding: 20px;
}

.ticket-json {
  width: 400px;
  background: #ffffff;
  padding: 0px 20px 50px 20px;
  border-radius: 5px;
}

.ticket-json table {
  width: 100%;
}

.ticket-json-label {
  text-align: left;
  padding: 5px 5px;
  font-size: 18px;
  width: 55px;
}

.ticket-json-price {
  text-align: right;
  padding: 5px 5px;
  font-size: 18px;
  width: 55px;
}